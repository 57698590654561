import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'

import trackingReducer from './trackingSlice';
import customerInfoReducer from './customerInfoSlice';

const store = configureStore({
  reducer: {
    tracking: trackingReducer,  
    customerinfo: customerInfoReducer 

  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)   
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store
