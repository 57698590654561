import axios from 'axios';
import { Modal } from '@mui/material';

export const TRACKINGAPI = axios.create({
  baseURL: process.env.REACT_APP_SAM_API_DOMAIN,
});
export const CUSTOMERAPI = axios.create({
  baseURL: process.env.REACT_APP_INTEGRATIONS_API_DOMAIN,
});
export const OMS = axios.create({
  baseURL: process.env.REACT_APP_OMS_DOMAIN,
  withCredentials: true,
});
function handleErrorResponse(error) {

  let roles = window.localStorage.getItem("roles")
  if (error && error.response && error.response.status) {
    if (window.location.pathname != "/login" &&
      window.location.pathname != "/register" &&
      window.location.pathname != "/" &&
      window.location.pathname != "/passwordreset") {
      if (401 === error.response.status || 419 === error.response.status) {
          window.location = '/login';
      } else if (403 === error.response.status) {
        if (roles.includes("labelgen") && !roles.includes("oms")) {
          Modal.warning({
            title: 'Forbidden',
            content: "You don't have access to this page!",
            okText: "Go To Labelgen",
            onOk: () => { window.location = process.env.REACT_APP_LABELGEN_DOMAIN + '/dashboard'; }
          });
        }
        else if (roles.includes("oms") && roles.includes("agent") || roles.includes("brand-admin")) {
          Modal.warning({
            title: 'Forbidden',
            content: "You don't have access to this page!",
            okText: "Go Home",
            onOk: () => { window.location = '/dashboard'; }
          });
        }
      } else if (500 === error.response.status) {
        Modal.warning({
          title: 'Internal Server Error',
          content: "Something went wrong please try again later",
          okText: "Go Home",
          onOk: () => { window.location = '/dashboard'; }
        });
      }
    }
  }
}

OMS.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  handleErrorResponse(error);
  return Promise.reject(error);
});