import { createSlice } from "@reduxjs/toolkit";
import { CUSTOMERAPI } from "./API";

export const customerInfoSlice = createSlice({
  name: "CustomerInfo",
  initialState: {
    customerDetail: {},
    loading: false,
    meta: [],
    hasErrors: false,
    message:""
  },
  reducers: {
    getCustomerDetail: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getCustomerDetailSuccess: (state, action) => {
      return {
        ...state,
        customerDetail:
          action &&
          action.payload &&
          action.payload.data &&
          action.payload.data.data,
        hasErrors: false,
        loading: false,
      };
    },
    getCustomerDetailFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        tracking: {},
      };
    },
    resetCustomerDetailNotification: (state, action) => {
      return {
        ...state,
        customerDetail: {},
      };
    },
  },
});
export const {
    getCustomerDetail,
    getCustomerDetailSuccess,
    getCustomerDetailFailure,
    resetCustomerDetailNotification,
} = customerInfoSlice.actions;

export const fetchCustomerDetails = (data) => (dispatch) => {
  const qs = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
  dispatch(getCustomerDetail());
  try {
    CUSTOMERAPI.get("/find-user?" + qs, {
      crossDomain: true,
      headers: {
        "x-api-key": "VCXbA4uiUT6ylFMTZHeyiiG8tgdRhn",
      },
    })
      .then(function (response) {
        dispatch(getCustomerDetailSuccess(response));
      })
      .catch(function (error) {
        dispatch(getCustomerDetailFailure(error.response));
      });
  } catch (error) {
    dispatch(getCustomerDetailFailure(error));
  }
};
export const resetCustomerDetail = (data) => (dispatch) => {
  dispatch(resetCustomerDetailNotification());
};
export default customerInfoSlice.reducer;
