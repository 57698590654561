import { createSlice } from "@reduxjs/toolkit";
import { TRACKINGAPI } from "./API";

export const trackingSlice = createSlice({
  name: "Tracking",
  initialState: {
    tracking: {},
    loading: false,
    meta: [],
    hasErrors: false,
    showSlider: false,
    notification: { show: false, messageContent: "", messageType: "success" },
  },
  reducers: {
    getTrackingDetail: (state) => {
      return {
        ...state,
        loading: true,
        showSlider: true,
      };
    },
    getTrackingDetailSuccess: (state, action) => {
      return {
        ...state,
        tracking:
          action &&
          action.payload &&
          action.payload.data &&
          action.payload.data.data &&
          action.payload.data.data,
        hasErrors: false,
        loading: false,
        notification: {
          show: true,
          messageContent:
            action &&
            action.payload &&
            action.payload.data &&
            action.payload.data &&
            action.payload.data.meta,
          messageType: "success",
        },
        showSlider: true,
      };
    },
    getTrackingDetailFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        notification: {
          show: true,
          messageContent: [
            action &&
              action.payload &&
              action.payload.data &&
              action.payload.data.message &&
              action.payload.data.message.meta,
          ],
          messageType: "error",
        },
        tracking: {},
      };
    },
    resetTrackingNotification: (state, action) => {
      return {
        ...state,
        tracking: {},
        notification: {
          show: false,
          messageContent: "",
          messageType: "success",
        },
      };
    },
  },
});
export const {
  getTrackingDetail,
  getTrackingDetailSuccess,
  getTrackingDetailFailure,
  resetTrackingNotification,
} = trackingSlice.actions;

export const fetchTrackingDetail = (data) => (dispatch) => {
  const qs = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
  dispatch(getTrackingDetail());
  try {
    TRACKINGAPI.get("/track?" + qs, {
      crossDomain: true,
      headers: {
        "x-api-key": "VCXbA4uiUT6ylFMTZHeyiiG8tgdRhn",
      },
    })
      .then(function (response) {
        dispatch(getTrackingDetailSuccess(response));
      })
      .catch(function (error) {
        dispatch(getTrackingDetailFailure(error.response));
      });
  } catch (error) {
    dispatch(getTrackingDetailFailure(error));
  }
};

export const resetTrackingEventNotification = (data) => (dispatch) => {
  dispatch(resetTrackingNotification());
};

export default trackingSlice.reducer;
