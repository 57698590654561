import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import _ from 'lodash';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import moment from "moment";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid } from '@mui/x-data-grid';
import { CardContent, Typography ,Tooltip} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InfoIcon from '@mui/icons-material/Info';
// import { resetTrackingEventNotification } from '../../pages/Reports/Deliveries/trackingEventsSlice';
// import Carrier from './Carrier'
import Carrier from './pages/General/Carrier';
import Notification from './pages/General/Notification';

// css
import './App.css';
import { fetchTrackingDetail, resetTrackingEventNotification } from './trackingSlice';
import { fetchCustomerDetails,resetCustomerDetail } from './customerInfoSlice';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const App = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search)
  let urlParam = params.get('t')
  const [trackingNumber, settrackingNumber] = useState("");
  const [isMultiPiece, setIsMultiPiece]= useState(false);
  const [trackingNumberErrors, setTrackingNumberErrors] = useState(null);
  const trackingDatas = useSelector(state => state.tracking);
  const customerDetails = useSelector(state => state.customerinfo);
 
  let iteration = 1;
  let dateExist = 1;

  const [isOpen, setIsOpen] = useState(false);
  const toggleprofile = () => setIsOpen(!isOpen);

  const trackHandleSubmit = () => {
    if (trackingNumber.trim() == '') {
      setTrackingNumberErrors("Tracking Number is required");
    } else if (trackingNumber.match(/[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g)) {
      setTrackingNumberErrors("Tracking Number is invalid");
    }
    else {
      setTrackingNumberErrors("")
      dispatch(resetTrackingEventNotification())
      dispatch(resetCustomerDetail())
      const data = { "carrier_auto_detect": true, 'tracking_number': trackingNumber }
      let trackingNumberData={'tracking_number': trackingNumber}
      dispatch(fetchCustomerDetails(trackingNumberData))
      dispatch(fetchTrackingDetail(data))
    }
    iteration = 1;
    dateExist = 1;
  }


  const [trackEventIcon, setTrackEventIcon] = useState("");
  const [trackingEventCircle, setTrackingEventCircle] = useState('');
  const [tag, setTag] = useState("");
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [carrierName, setCarrierName] = useState("");
  const [dateCheck, setDateCheck] = useState("");
  const [events, setEvents] = useState({});
  const [trackingData, setTrackingData] = useState({});
  const [trackVisible, setTrackVisible] = useState(false);

  const columns = [
    {
         field: 'tracking_number', 
         headerName: 'Tracking number',
         flex: 1,
         renderCell: (params) => {
            return (
                <p>{params.row.tracking_number}</p> 
               
            )
        }
    },
    
    {
        field: 'current_status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
            return (
              <Chip label={params.row.current_status} color="primary" />
          )
        }
    },
    {
        field: 'action',
        headerName: 'Tracking Details',
        minWidth: 200,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            const trackingNumber = params.row.tracking_number ? params.row.tracking_number : "";
                return <div>
                    <span style={{ marginRight: '10px' }}><Tooltip title="View" placement="left-start">
                        <IconButton>
                            <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={e => { trackingCallback(params.row.tracking_number) }} />
                        </IconButton>
                    </Tooltip>
                    </span>
                </div>
        }
    }
]

const trackingCallback = (trackingNumber) => {
  let output = trackingDatas.tracking.filter(({ tracking_number }) =>[trackingNumber].includes(tracking_number));
   let finaloutput=Object.assign({}, output[0]);
   setTrackingData(finaloutput)
   setTrackVisible(true)
   
      if (typeof finaloutput !== "undefined") {
       if (finaloutput && finaloutput.current_status_formatted === "DELIVERED") {
           setTag("rgb(0, 153, 0)")
           setShipmentStatus("delivered")
       } else if (finaloutput && finaloutput.current_status_formatted &&
           (finaloutput.current_status_formatted === "IN TRANSIT" || finaloutput.current_status_formatted === "PICKED UP" || finaloutput.current_status_formatted === "MIDDLE MILE")) {
           setTag("#09c2de")
           setShipmentStatus("transit")
       } else if (finaloutput && finaloutput.current_status_formatted === "EXCEPTION") {
           setTag("#e85347")
           setShipmentStatus("exception")
           setTrackingEventCircle("exception-md-step-circle md-step-circle")
           setTrackEventIcon("!")
       }
       else if (finaloutput && finaloutput.current_status_formatted === "RETURN") {
           setTag("#e85347")
           setShipmentStatus("exception")
           setTrackingEventCircle("exception-md-step-circle md-step-circle")
           setTrackEventIcon("↵")
       }
       else if (finaloutput && finaloutput.current_status_formatted &&
           (finaloutput.current_status_formatted === "ATTEMPTED" || finaloutput.current_status_formatted === "SHIPMENT ON HOLD" ||
           finaloutput.current_status_formatted === "OUT FOR DELIVERY")) {
           setTag("#e85347")
           setShipmentStatus("exception")
           setTrackingEventCircle("otherevents-md-step-circle md-step-circle")
           setTrackEventIcon("➜")
       }
       else if (finaloutput && finaloutput.current_status_formatted && (finaloutput.current_status_formatted === "ORDER CREATED" || finaloutput.current_status_formatted === "INFO RECEIVED" || finaloutput.current_status_formatted === "LABEL GENERATED")) {
           setTag("rgb(0, 153, 0)")
           setShipmentStatus("ordercreated")
       }
   }
   setDateCheck((finaloutput && finaloutput["events"] && finaloutput["events"][0] && finaloutput["events"][0]["date"]) ? finaloutput["events"][0]["date"] : "");
   setCarrierName(typeof finaloutput && finaloutput && (finaloutput.carrier_name !== "undefined" ? finaloutput.carrier_name : ""));
}

  useEffect(() => {
    if (urlParam) {
      if(urlParam.charAt(0)=='M'){
        setIsMultiPiece(true)
      }else{
        setIsMultiPiece(false)
      }
      settrackingNumber(urlParam)
      dispatch(resetTrackingEventNotification())
      dispatch(resetCustomerDetail())
      const data = { "carrier_auto_detect": true, 'tracking_number': urlParam }
      let trackingNumberData={'tracking_number': urlParam}
      dispatch(fetchCustomerDetails(trackingNumberData))
      dispatch(fetchTrackingDetail(data))
    }
  }, [urlParam]);

  React.useEffect(() => {
   
    if (typeof trackingDatas !== "undefined" && isMultiPiece==false) {
        let finaloutput=trackingDatas && trackingDatas.tracking;
        setTrackingData(finaloutput)
        setTrackVisible(true)
        if (typeof finaloutput !== "undefined") {
            if (finaloutput && finaloutput.current_status_formatted === "DELIVERED") {
                setTag("rgb(0, 153, 0)")
                setShipmentStatus("delivered")
            } else if (finaloutput && finaloutput.current_status_formatted &&
                (finaloutput.current_status_formatted === "IN TRANSIT" || finaloutput.current_status_formatted === "PICKED UP" || finaloutput.current_status_formatted === "MIDDLE MILE")) {
                setTag("#09c2de")
                setShipmentStatus("transit")
            } else if (finaloutput && finaloutput.current_status_formatted === "EXCEPTION") {
                setTag("#e85347")
                setShipmentStatus("exception")
                setTrackingEventCircle("exception-md-step-circle md-step-circle")
                setTrackEventIcon("!")
            }
            else if (finaloutput && finaloutput.current_status_formatted === "RETURN") {
                setTag("#e85347")
                setShipmentStatus("exception")
                setTrackingEventCircle("exception-md-step-circle md-step-circle")
                setTrackEventIcon("↵")
            }
            else if (finaloutput && finaloutput.current_status_formatted &&
                (finaloutput.current_status_formatted === "ATTEMPTED" || finaloutput.current_status_formatted === "SHIPMENT ON HOLD" ||
                finaloutput.current_status_formatted === "OUT FOR DELIVERY")) {
                setTag("#e85347")
                setShipmentStatus("exception")
                setTrackingEventCircle("otherevents-md-step-circle md-step-circle")
                setTrackEventIcon("➜")
            }
            else if (finaloutput && finaloutput.current_status_formatted && (finaloutput.current_status_formatted === "ORDER CREATED" || finaloutput.current_status_formatted === "INFO RECEIVED" || finaloutput.current_status_formatted === "LABEL GENERATED")) {
                setTag("rgb(0, 153, 0)")
                setShipmentStatus("ordercreated")
            }
        }
        setDateCheck((finaloutput && finaloutput["events"] && finaloutput["events"][0] && finaloutput["events"][0]["date"]) ? finaloutput["events"][0]["date"] : "");
        setCarrierName(typeof finaloutput && finaloutput && (finaloutput.carrier_name !== "undefined" ? finaloutput.carrier_name : ""));        
    }else if(trackingDatas && trackingDatas.tracking == undefined){
        setTrackVisible(true) 
    }

}, [trackingDatas])


  return (
    <>
      <header className="header">
        <div className="wrap">
          <h2 className="logo"><a href="https://www.shippingchimp.com/"><img className="loginlogo" src="/assets/images/shippingchimp-logo-white.png" alt="logo-dark" /></a></h2>
          <a id="menu-icon" onClick={toggleprofile}>&#9776; Menu</a>
          <nav className="navbar">
            <ul className={`${isOpen ? 'menu' : 'menu-hide'}`}>
              <li><a className="active" href="https://www.shippingchimp.com/">Home</a></li>
              <li><a href="https://www.shippingchimp.com/pricing">Pricing</a></li>
              <li><a href="https://www.shippingchimp.com/how-it-works">How it works</a></li>
              <li><a href="https://www.shippingchimp.com/contact">Contact</a></li>
            </ul>
          </nav>
        </div>
      </header>
      <div className="content">

        <Grid container spacing={1}>
          <Grid item xs={12} md={12} style={{ padding: '15px' }}>
            <Item style={{ padding: '15px' }}>
              <div className="sc--form--center">
                <TextField
                  autoFocus
                  id="input-with-icon-textfield"
                  name="tracking_number"
                  label="Tracking Number"
                  value={trackingNumber}
                  size='small'
                  style={{ width: '350px' }}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (trackingNumber) ? (
                      <IconButton size="small" onClick={e => { 
                        settrackingNumber("") 
                        dispatch(resetTrackingEventNotification())
                        }}>
                        <ClearIcon />
                      </IconButton>
                    ) : undefined
                  }}
                  onChange={e => { 
                    dispatch(resetTrackingEventNotification())
                    settrackingNumber(e.target.value)
                    if(e.target.value.charAt(0)=='M'){
                      setIsMultiPiece(true)
                    }else{
                      setIsMultiPiece(false)
                    }
                   }}
                  onKeyUp={(event) => {
                    if (event.key == 'Enter')
                      trackHandleSubmit()
                  }}
                  error={trackingNumberErrors}
                  helperText={trackingNumberErrors}
                />
              </div>
              <div className="sc--form--center sc--track--button" style={{ marginTop: '15px', position: 'relative', right: '135px' }}>
                <Button variant="contained" onClick={trackHandleSubmit}>Track</Button>
              </div>

            </Item>
            {trackingDatas && trackingDatas.loading === true && <>
              <LinearProgress />
            </>
            }
          </Grid>
        </Grid>

        {trackingDatas && trackingDatas.loading === false &&  isMultiPiece && trackingDatas && trackingDatas.tracking !== undefined && Object.keys(trackingDatas.tracking).length != 0  && trackingDatas.notification.messageContent.carrier_status==true &&<>
          <CardContent>
              <div style={{ maxWidth: '100%' }}>
                  <DataGrid
                      getRowId={(row) => row.tracking_number}
                      disableSelectionOnClick={true}
                      rows={trackingDatas && trackingDatas.tracking}
                      columns={columns}
                      autoHeight={true}
                      components={{
                          LoadingOverlay: LinearProgress,
                      }}
                      hideFooter={true}
                      loading={trackingDatas && trackingDatas.loading}
                  />
              </div>
            </CardContent>
            </>
      }

        {trackingDatas && trackingDatas.notification && trackingDatas.notification.messageContent &&  trackingDatas.notification.messageContent[0] && trackingDatas.notification.messageContent[0].carrier_status==false &&
              <>
               <Item style={{ margin: '30px 0' }}>
                 <Stack sx={{ width: '100%' }} spacing={2}>
                   <Alert severity="error">{trackingDatas && trackingDatas.notification && trackingDatas.notification.messageContent &&  trackingDatas.notification.messageContent[0] && trackingDatas.notification.messageContent[0].message!=='' ? trackingDatas && trackingDatas.notification && trackingDatas.notification.messageContent &&  trackingDatas.notification.messageContent[0] && trackingDatas.notification.messageContent[0].message: "Tracking number invalid or unknown"}</Alert>
                 </Stack>
               </Item>
               <Item style={{ margin: '30px 0' }}>
                 <Stack sx={{ width: '100%' }} spacing={2}>
                   <Alert severity="warning">
                     <AlertTitle style={{ padding: ' 0 30px', textAlign: 'left', color: 'rgb(102, 60, 0)' }}>Shipping information not found for <b>{(trackingDatas && trackingDatas.notification && trackingDatas.notification.messageContent &&  trackingDatas.notification.messageContent[0] && trackingDatas.notification.messageContent[0].tracking_number)}</b></AlertTitle>
                     <p style={{ padding: '0 30px', textAlign: 'left' }}>We don’t have information for this trackingnumber due to one of the following reasons.</p>
                     <ul>
                       <li style={{ padding: '5px 0px', textAlign: 'left' }}>Might be latest shipment information not available with carrier</li>
                       <li style={{ padding: '5px 0px', textAlign: 'left' }}>Might be shipment manifest not yet closed</li>
                     </ul>
                   </Alert>
                 </Stack>
               </Item>
             </>
          }

      {customerDetails && customerDetails.loading === false && trackingDatas && trackingDatas.loading === false && trackingDatas && trackingDatas.tracking !== undefined && trackingDatas.notification.messageContent.carrier_status==true && Object.keys(trackingDatas.tracking).length != 0 && (trackVisible || isMultiPiece==false) &&<>
          <div className="track__details">
            {customerDetails && customerDetails.customerDetail && customerDetails.customerDetail.company && customerDetails.customerDetail.company!=='' &&
                <div style={{display:'flex',justifyContent:'space-between'}}>
                   <h5 className="sc--text--center my-2" style={{ fontSize: '16px' }}>{trackingData.tracking_number}</h5>
                   {customerDetails && customerDetails.customerDetail && customerDetails.customerDetail.logo_url && customerDetails.customerDetail.logo_url!=='' ?
                      <img src={`${customerDetails && customerDetails.customerDetail && customerDetails.customerDetail.logo_url}`} style={{maxWidth:'200px',height:'auto',padding:'10px 15px'}} alt='logo' /> 
                      :
                      <h5 className="sc--text--center my-2" style={{ fontSize: '16px' }}>{customerDetails.customerDetail.company}</h5>
                   }
                </div>
            }
          {Object.keys(customerDetails && customerDetails.customerDetail).length==0 && <h5 className="sc--text--center my-2" style={{ fontSize: '16px' }}>{trackingData.tracking_number}</h5> }
            <h3 style={{ color: tag, textAlign: 'center' }}>
              {(trackingData && trackingData.current_status_formatted && trackingData.current_status_formatted !== "") && trackingData.current_status_formatted}
              {(trackingData && trackingData.current_status_formatted && trackingData.current_status_formatted !== ""
                && trackingData.disputes && trackingData.disputes.returns === true) && <span style={{ paddingLeft: '7px', position: 'relative', top: '-4px' }} ><Chip color="warning" size="small" label="Returns" /></span>}
              {(!trackingData && trackingData.current_status_formatted && trackingData.message) && trackingData.message}
              {trackingData && 
              trackingData["events"] &&<>
               <span className="sc--event--hide" style={{fontSize:'16px',color:'#000'}}> - </span> <span className="sc--trackdate--span" style={{fontSize:'16px',color:'#000'}}>
              {trackingData["events"] && trackingData["events"][0] && <>
              {(trackingData["events"][0]["utc_date"] && trackingData["events"][0]["utc_date"] != "" && moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("dddd"))}
              {(trackingData["events"][0]["utc_date"] && trackingData["events"][0]["utc_date"] != "" && ", " + moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("MMM"))}
              {(trackingData["events"][0]["utc_date"] && trackingData["events"][0]["utc_date"] != "" && " " + moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("DD"))}
              {(trackingData["events"][0]["utc_time"] && trackingData["events"][0]["utc_time"] != "" && " at " + moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("HH:mm"))}
              </>}
            </span>
            </>
              }
            </h3>
            {trackingData && trackingData.pod_file_url && trackingData.pod_file_url!=='' && <p style={{textAlign:'center'}}><a href={trackingData.pod_file_url} style={{color:'#0467C6',paddingBottom:'20px'}} target='_blank'>View delivery confirmation</a></p>}
            {trackingData && 
              trackingData["events"] &&
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} style={{ padding: '15px' }}>
                    <Item>
                      {shipmentStatus == 'ordercreated' &&
                        <div className="md-stepper-horizontal orange">
                          <div className="md-step active done">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">{trackingData.current_status_formatted}</div>
                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                            <div className="md-step-bar-left transit-md-step-bar-left"></div>
                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                            <div className="md-step-bar-left transit-md-step-bar-left"></div>
                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="transit-md-step-circle md-step-circle"><span>✓</span></div>
                            <div className="transit-md-step-bar-left md-step-bar-left"></div>
                          </div>
                        </div>
                      }
                      {shipmentStatus == 'transit' &&
                        <div className="md-stepper-horizontal orange">
                          <div className="md-step active done">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">CREATED</div>
                            <div className="md-step-bar-right"></div>
                          </div>
                          <div className="md-step active editable">
                            <div className="md-step-circle"><span>➜</span></div>
                            <div className="step-title">{trackingData.current_status_formatted}</div>
                            <div className="md-step-bar-left"></div>
                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                            <div className="md-step-bar-left transit-md-step-bar-left"></div>
                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="transit-md-step-circle md-step-circle"><span>✓</span></div>
                            <div className="transit-md-step-bar-left md-step-bar-left"></div>
                          </div>
                        </div>
                      }
                      {shipmentStatus == 'delivered' &&
                        <div className="md-stepper-horizontal orange">
                          <div className="md-step active done">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">CREATED</div>
                            <div className="md-step-bar-right"></div>
                          </div>
                          <div className="md-step active editable">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">PICKED UP</div>
                            <div className="md-step-bar-left"></div>
                            <div className="md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">IN TRANSIT</div>
                            <div className="md-step-bar-left"></div>
                            <div className="md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">{trackingData.current_status_formatted}</div>
                            <div className="md-step-bar-left"></div>
                          </div>
                        </div>
                      }
                      {shipmentStatus == 'exception' &&
                        <div className="md-stepper-horizontal orange">
                          <div className="md-step active done">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">CREATED</div>
                            <div className="md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="md-step-circle"><span>✓</span></div>
                            <div className="step-title">PICKED UP</div>
                            <div className="md-step-bar-left"></div>
                            <div className="md-step-bar-right"></div>
                          </div>
                          <div className="md-step active editable">
                            <div className={trackingEventCircle}><span>{trackEventIcon}</span></div>
                            <div className="step-title">{trackingData.current_status_formatted}</div>
                            <div className="md-step-bar-left"></div>
                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                          </div>
                          <div className="md-step active">
                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                            <div className="md-step-bar-left transit-md-step-bar-right"></div>
                          </div>
                        </div>
                      }
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} style={{ padding: '15px' }}>
                    <Item>
                      <ul style={{ display: 'flex', margin: 'auto' }}>

                        <li style={{ listStyle: 'none', width: '30%' }}><p><b>Ship Date: </b>  {trackingData && trackingData["date"] && trackingData["date"]["shipped"] === "" ?
                          '-' : trackingData && trackingData["date"] && trackingData["date"]["shipped"]}</p></li>
                          <li style={{ listStyle: 'none', width: '30%' }}><p style={{display:'flex',justifyContent:'center'}}>
                          <b>Estimated Delivery: </b>  
                          <span style={{paddingLeft:'5px'}}>
                          {trackingData && trackingData["date"] && trackingData["date"]["estimated"] === "" ?
                          '-' : trackingData && trackingData["date"] && trackingData["date"]["estimated"]}</span>
                          {
                          (trackingData && trackingData["date"] && trackingData["date"]["estimated"] !=='') ? <Tooltip title="The estimated delivery date is approximate, and your packages may arrive within one day earlier or later than the estimated date." placement="top"><InfoIcon style={{paddingLeft:'5px'}} /></Tooltip> : ""
                          }
                          </p>
                          </li>
                        <li style={{ listStyle: 'none', width: '30%' }}><p><b>Delivery Date: </b> {trackingData && trackingData["date"] && trackingData["date"]["delivery"] === "" ?
                          '-' : trackingData && trackingData["date"] && trackingData["date"]["delivery"]}</p></li>
                      </ul>
                    </Item>
                  </Grid>
                </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} style={{ padding: '15px' }}>
                  <Item style={{ border: 'unset',marginBottom:'30px' }}>
                    <h5 className="my-4" style={{ fontSize: '16px',margin:'15px 0' }}>Travel History</h5>
                    {trackingData["events"] && trackingData["events"].map((text, item) => {

                      if (dateExist !== moment.utc(text.utc_date+" "+text.utc_time).local().format("YYYY-MM-DD") || iteration === 1) {
                          dateExist = moment.utc(text.utc_date+" "+text.utc_time).local().format("YYYY-MM-DD")
                          iteration = iteration + 1
                          return <>
                              <>
                                  <div className="card-header" style={{ textAlign: 'left' }}>{dateExist}</div>
                                  <ul className="list-group-flush">
                                      <li className="list-item">{moment.utc(text.utc_date+" "+text.utc_time).local().format("HH:mm:ss")}</li>
                                      <li className="list-item" style={{ width: "30%" }}>{text.city} {text.province_code}</li>
                                      <li className="list-item">{text.event}</li>
                                  </ul>
                              </>
                          </>
                      } else {
                          return <>
                              <ul className="list-group-flush">
                                  <li className="list-item">{moment.utc(text.utc_date+" "+text.utc_time).local().format("HH:mm:ss")} </li>
                                  <li className="list-item" style={{ width: "30%" }}>{text.city} {text.province_code}</li>
                                  <li className="list-item">{text.event}</li>
                              </ul>
                          </>
                      }
                      })}
                    {/* {events && Object.keys(events).map((item) => {

                      return (
                        <>
                          <div className="card-header" style={{ textAlign: 'left' }}>{item}</div>
                          {
                            events[item].map((i) => {
                              return (
                                <ul className="list-group-flush">
                                  <li className="list-item">{i.time}</li>
                                  <li className="list-item" style={{ width: "30%" }}>{i.city} {i.province_code}</li>
                                  <li className="list-item">{i.event}</li>
                                </ul>
                              )
                            })
                          }

                        </>
                      )
                    })} */}
                  </Item>
                </Grid>
              </Grid>
              </>
            }
          </div>
        </>
        }
    </div>
    <div className="footer">Copyright © {new Date().getFullYear()} ShippingChimp Inc. <span className="sc--footer--span">All Rights Reserved</span></div>
    </>
  )
};

export default App;